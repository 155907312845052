table {
  font-family: Arial Narrow, Aria, sans-serif;

  &.table-info {
    table-layout: fixed;
    border-collapse: collapse;
    width: rem(1000);
    @include media(lg) {
      width: 100%;
    }

    thead {
      .sort-arrows {
        button {
          svg {
            path {
              fill: #979797;
            }
          }

          &.active-arrow {
            svg {
              path {
                fill: #000000;
              }
            }
          }
        }
      }
    }

    &.table-unassigned-job-shifts {
      thead {
        th:last-child {
          width: rem(75);
        }
      }

      tbody {
        tr {
          td:last-child {
            width: rem(75);
          }

          .custom-control.custom-checkbox {
            label {
              &:hover {
                cursor: pointer;
              }

              &:before {
                top: rem(-11);
                width: rem(20);
                height: rem(20);
              }

              &:after {
                top: rem(-11);
                width: rem(20);
                height: rem(20);
              }
            }
          }
        }
      }
    }

    &.table-info--notFixed {
      table-layout: auto;

      thead {
        .sort-arrows {
          button {
            height: auto;
          }

          span.sortable {
            flex: 0;
          }
          span {
            white-space: pre;
          }
        }
      }
    }

    &.table-info-job-schedule {
      table-layout: fixed;
      width: rem(1000);
      @include media(lg) {
        width: 100% !important;
      }

      thead th:nth-child(3) {
        @include media(md) {
          width: 80px;
        }
        @include media(xl) {
          width: auto !important;
        }
      }

      & guard {
        thead {
          th:nth-child(1) {
            @include media(md) {
              width: 80px;
            }
            @include media(xl) {
              width: auto !important;
            }
          }

          th:nth-child(3) {
            @include media(md) {
              width: 80px;
            }
            @include media(xl) {
              width: auto !important;
            }
          }
        }
      }
    }

    &.available-guards-on-job {
      tbody {
        tr {
          td {
            .custom-control.custom-control-radio {
              padding: 0;
              min-height: auto;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              label {
                width: 100%;
                height: rem(50);

                &:hover {
                  cursor: pointer;
                }

                &:after {
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  //   top: -5px;
                }

                &:before {
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  //   top: -11.5px;
                }
              }
            }
          }
        }
      }
    }

    thead {
      tr {
        th {
          font-size: rem(14);
          font-weight: 700;
          background-color: #dfedf2;
          border: 0;
          color: var(--dark);
          padding: 0 5px;
          line-height: 16px;
          vertical-align: middle;
          > div {
            min-height: rem(50);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .sort-arrows {
            button {
              flex: 1;
              height: auto;

              svg {
                flex: 1;
              }
            }
          }
        }
      }
    }

    &.table-info--lastColHolidays {
      width: rem(1000);
      @include media(xl) {
        width: 100% !important;
      }

      thead {
        tr {
          th {
            &:last-child {
              border: 0;
              background-color: white;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:last-child {
              border: 0;
              background-color: white;
              width: rem(225);

              .holidays-item__name {
                min-height: auto;
                font-size: rem(14);
                padding: 0;
                max-width: 88%;

                &:before {
                  margin-left: rem(-18);
                }

                span {
                  padding: rem(8) rem(5);
                  width: rem(200);
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  text-align: left;
                }

                svg {
                  flex-basis: 34px;
                  flex-grow: 0;
                  flex-shrink: 0;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        background-color: var(--grey-light-2);
        border: 0;

        &.guard-for-schedule--selected {
          background-color: #73d2de !important;
        }

        td {
          border: 0;
          font-size: rem(13);
          font-weight: 400;
          line-height: 16px;
          color: var(--dark);
          padding: 0;
          height: auto;
          @include dropdown;

          &.isLink {
            color: var(--primary);
            text-decoration: underline;

            &:hover {
              cursor: pointer;
            }
          }

          &.mw-400 {
            width: rem(300);
            @include media(md) {
              width: rem(400);
            }
          }

          button {
            height: rem(30);
            width: rem(30);
            padding: 0;
            flex-shrink: 0;
            justify-content: center;

            svg {
              flex-shrink: 0;
            }

            &:after {
              display: none;
            }
          }

          > div {
            // &:not(&.dropdown), &:not(&.dropdown-menu) {
            min-height: rem(50);
            // height: 100%;
            height: auto;
            padding: rem(1) rem(5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // }
          }

          .dropdown {
            padding: 0;
          }

          &.late-on-asp {
            span {
              font-weight: 600;
              background: #ffb20f;
              padding: rem(5) rem(5);
              color: #fff;
              display: inline-block;
            }
          }

          &.late-on-client {
            span {
              font-weight: 600;
              background: #cc0001;
              color: #fff;
              padding: rem(5) rem(5);
              display: inline-block;
            }
          }
        }

        &:nth-child(odd) {
          background-color: var(--grey-light-1);
        }
      }

      .table-info__status {
        &--success {
          color: #78bc61;
        }

        &--warning {
          color: #ffb20f;
        }

        &--danger {
          color: #cc0001;
        }

        &--secondary {
          color: #bcb8b2;
        }
      }
      .table-info__status-background {
        width: rem(24);
        height: rem(24);
        &--success {
          background-color: #78bc61;
        }

        &--warning {
          background-color: #ffb20f;
        }

        &--danger {
          background-color: #cc0001;
        }

        &--secondary {
          background-color: #bcb8b2;
        }
      }
      .table-info__scheduleStatus {
        height: rem(24);

        &--empty {
          min-width: rem(24);
          background-color: var(--green-light-1);
        }

        &--nonEmpty {
          background-color: var(--yellow-light-1);
          color: var(--primary-dark);
        }
      }

      .table-info__counter {
        height: rem(24);
        min-width: rem(24);
        font-size: rem(13);
        color: var(--dark);
        font-weight: 600;
        &--best {
          background-color: var(--green-light-1);
          font-weight: 600;
        }

        &--good {
          background-color: var(--yellow-light-1);
          font-weight: 600;
        }

        &--worst {
          font-weight: 600;
          color: #fff;
          background-color: var(--danger);
        }
      }
    }
  }

  &.guard-for-schedule {
    border: 1px solid #bcb8b2;

    tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
  &.table-sticky {
    thead {
      tr th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
      }
    }
    tbody {
      tr td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: var(--grey-light-2);
      }
      tr:nth-child(odd) {
        td:first-child {
          background-color: var(--grey-light-1);
        }
      }
    }
  }
  &.table--dashboard {
    tbody {
      tr {
        td {
          div {
            button.btn {
              width: rem(30) !important;
              height: rem(30) !important;
            }
          }
        }
      }
    }
  }
}

.table-info {
  &__pagination {
    ul {
      display: flex;
      list-style: none;
      width: 100%;
      padding-left: 0;
      justify-content: center;
      align-items: center;

      li {
        a {
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &:first-child {
          margin-right: auto;
        }

        &:last-child {
          margin-left: auto;
        }

        &:not(:first-child):not(:last-child) {
          a {
            font-size: rem(15);
            font-weight: 700;
            color: var(--primary);
            text-decoration: underline;
            padding: 0 rem(10);
          }
        }

        &.selected {
          a[role="button"] {
            text-decoration: none;
            color: var(--dark-lighter);
          }
        }
      }
    }
  }

  &__perPage {
    label {
      font-size: rem(14);
      font-weight: bold;
      color: var(--dark);
    }

    .custom-react-select {
      width: rem(60);
      height: rem(44);
    }
  }

  &__pages {
    li {
      font-size: rem(15);
      font-weight: bold;
      color: var(--primary);
      text-decoration: underline;
      padding: 0 rem(12);

      &:hover {
        cursor: pointer;
      }

      &.current {
        color: var(--dark-lighter);
        text-decoration: none;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}

.previousClassName {
  position: absolute;
  left: 3%;
  bottom: -2.5%;
  @include media(sm) {
    position: relative;
    left: 0%;
  }
}

.nextClassName {
  position: absolute;
  right: 3%;
  bottom: -2.5%;
  @include media(sm) {
    position: relative;
    right: 0%;
  }
}

// Notifications Table
table {
  &.table--notifications {
    thead {
      tr {
        th:first-child {
          width: 57%;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          width: 57%;
        }
      }
    }
  }

  .notification-status {
    button.btn {
      width: rem(100) !important;
    }

    &--active {
      .notification-status__circle {
        background: #22cf6b;
      }
    }

    &__circle {
      width: rem(16);
      height: rem(16);
      border-radius: 50%;
      background: #dadada;
    }
  }
}

// Manage options table
.details-tabs--manage-dropdown {
  .details-tabs-content {
    table {
      thead {
        th:first-child {
          width: 75%;
        }
      }

      tbody {
        td:first-child {
          width: 75%;
        }
      }
    }
  }
}
.over-header {
  p,
  span {
    font-size: rem(14);
  }
}
.shift-report {
  .table-responsive-lg {
    overflow-x: auto;
    .table-info {
      width: 190.5rem;
    }
  }
}

table {
  &.table-daily-attendance {
    thead {
      tr {
        th:first-child {
          width: rem(150);
        }
        th:nth-child(2) {
          min-width: rem(75);
          width: rem(75);
        }
        th:nth-child(n + 2) {
          white-space: nowrap;
        }
      }
    }
    tbody {
      tr {
        td {
          p {
            &.highlighted--yellow {
              background-color: yellow;
            }
            &.highlighted--blue {
              background-color: #6aabeb;
            }
            max-width: 185px;
            margin: 0 auto;
            // line-height: 1.4;
            -webkit-line-clamp: 30;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  &.table-info-hover {
    tbody {
      tr {
        &:hover {
          td {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }
    &.table-incident-reports {
      tbody {
        tr {
          &:hover {
            td:last-child {
              cursor: default;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
