.content-editor {
    &__wrapper {
        border: 1px solid var(--dark-lighter--2);
        background: #ffffff;
    }
    &__toolbar {
        border: 0 !important;
        border-bottom: 1px solid var(--dark-lighter--2) !important;
        .rdw-option-wrapper {
            border: 0;
            &:hover {
                box-shadow: none;
            }
        }
    }
    .rdw-link-decorator-wrapper {
        span {
            color: var(--primary);
        }
        &:hover a > span {
            text-decoration: underline;
        }
    }
    min-height: rem(175);
    padding: rem(10) rem(15);
}
