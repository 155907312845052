.holidays {
    &-warning {
        small {
            color: var(--danger);
            font-size: rem(14);
        }
    }
    &-item {
        &__date {
            font-size: 14px;
            font-weight: bold;
            color: var(--dark-lighter);
        }
        &__name {
            color: var(--dark-lighter);
            font-size: rem(15);
            border: 1px solid var(--alto);
            //height: rem(40);
            // padding: rem(5) rem(35) rem(5) rem(10);
            position: relative;
            border-left: 0;
            small {
                &:hover {
                    cursor: pointer;
                }
            }
            &:before {
                content: "";
                display: flex;
                width:rem(31);
                margin-left: rem(-16);
                background: linear-gradient(-45deg, white 17px, var(--alto) 17px, var(--alto) 18px, transparent 18px), linear-gradient(225deg, white 17px, var(--alto) 17px, var(--alto) 18px, transparent 18px);
                background-position: left 0px, right bottom;
                background-size: 100% 50%;
                background-repeat: no-repeat;
            }
            span {
                padding: rem(5) rem(35) rem(5) rem(10);
                // width: 100%;
                max-width: rem(215);
                @include media(sm) {
                    max-width: rem(350);
                }
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: left;
            }
        }
    }
}
