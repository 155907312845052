body {
  .react-datepicker-custom-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(10);
    }
    svg:hover {
      cursor: pointer;
    }

    &--full-width {
      .react-datepicker-wrapper {
        width: 100%;
        input {
            font-family: $font-family-base;
            font-weight: 700;
            font-size: rem(15);
            color: #000;
            border: 1px solid #BCB8B2;
            border-radius: rem(6);
            height: rem(44);
            padding: rem(10);
            // max-width: rem(155);
            // @include media(lg) {
                // max-width: rem(180);
                max-width: 100%;
            // }
            width: 100%;
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 20;
  }
  .ts {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        right: 0;
        left: auto !important;
        @include media(sm) {
          right: auto;
        }
      }
    }

  }
  .react-datepicker-wrapper {
    input {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: rem(15);
      color: #000;
      border: 1px solid #BCB8B2;
      border-radius: rem(6);
      height: rem(44);
      padding: rem(5);
      @media screen and (min-width: 340px) {
        padding: rem(10);
      }
      //@include media(sm) {
      //  padding: rem(10);
      //}
      max-width: rem(180);
      width: 100%;
    }
  }

  .datepicker-width {
    width: 47.6%;
    @include media(sm) {
      width: auto !important;
    }
  }

  .rc-time-picker-panel-combobox {
    display: flex;
  }

  .react-time-picker {
    border: 1px var(--dark-lighter--2) solid;
    border-radius: 6px;
    padding: 10px 6px;

    &__clear-button {
      display: none;
    }
    .rc-time-picker-panel {
        .rc-time-picker-panel-input {
            font-family: $font-family-base;
            font-weight: 700;
            font-size: rem(15);
            color: #000;
            &:focus {
                outline: none;
                box-shadow: none
            }
        }
        .rc-time-picker-panel-select {
            ul {
                li {
                    font-weight: 400;
                    font-size: rem(12);
                    color: #000;
                    &:focus {
                        outline: none;
                        box-shadow: none
                    }
                    &.rc-time-picker-panel-select-option-selected {
                        background: #EEEEE5 !important;
                        font-weight: 700;
                    }
                }
            }
        }
      }
    }


  .custom-control-timepicker {
    border: 1px solid var(--dark-lighter--2);
    border-radius: 6px;
    padding: 7px 6px;
    width: rem(106);

    .rc-time-picker-input {
      border: 0;
      padding: 0;
      width: rem(92) !important;
      font-family: $font-family-base;
      font-weight: 700;
      font-size: rem(15);
      color: #000;

      &:focus {
        outline: none;
        box-shadow: none
      }
    }
  }

  .rc-time-picker-panel {
    .rc-time-picker-panel-input {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: rem(15);
      color: #000;

      &:focus {
        outline: none;
        box-shadow: none
      }
    }

    .rc-time-picker-panel-select {
      ul {
        li {
          font-weight: 400;
          font-size: rem(12);
          color: #000;

          &:focus {
            outline: none;
            box-shadow: none
          }

          &.rc-time-picker-panel-select-option-selected {
            background: var(--aqua-blue) !important;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 369px) {
  .datepicker-width {
    width: 47%!important;
  }
}
@media screen and (min-width: 420px) {
  .datepicker-width {
    width: auto!important;
  }
}

