.reports-screen {
    span {
        &.divider {
            margin: rem(20) 0 rem(36) 0;
            display: flex;
            width: 100%;
            height: rem(1);
            background-color: var(--alto);
        }
    }
    table {
        .badge {
            display: block;
            width: rem(15);
            height: rem(15);
            border-radius: 50%;
            // position: relative;
            &-tooltip {
                position: absolute;
                left: 0;
                right: 0;
                top: -40%;
                margin: auto;
                width: rem(108);
                height: rem(35);
                border: 1.5px solid #BCB8B2;
                box-shadow: 0px 1px 4px rgba(40, 49, 69, 0.3);
                background-color: var(--grey-light-3);
                color: var(--dark);
                font-weight: 400;
                font-size: rem(14);
                line-height: 20px;
                padding: rem(5);
                text-align: left;
                border-radius: rem(8);
                opacity: 0;
                transition: 0.25s ease-in-out;
            }

            &:hover {
                cursor: pointer;
                .badge-tooltip {
                    opacity: 1;
                }
            }

        }
    }
}

.table-arrival-reports {
    tbody {
        tr {
            td:last-child {
                div {
                    @include truncatedTextTable(200)
                }
            }
        }
    }
}

.table-incident-reports {
    tbody {
        tr {
            td:nth-child(7) {
                div {
                    @include truncatedTextTable(200)
                }
            }
        }
    }
}