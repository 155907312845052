div.modal {
  .guard-safety__border{
    border: 3px solid #CC0001;
    border-radius: rem(8);
  }

  .guard-safety__bg-warn{
    background-color: #FFF6EF;
  }

  .guard-message--border {
    border: 3px solid #283044;
    border-radius: rem(8);
  }

  &-dialog{
    @include media(sm) {
      max-width: rem(580);
    }

    .modal-content{
      .modal-body.custom_body {
        position: relative;
        padding: rem(27) rem(5) rem(36)!important;
        @include media(sm) {
          padding: rem(27) rem(27) rem(36)!important;
        }
        a{
          color: #19657E;
        }
        .btn-close-modal{
          position: absolute;
          top: 0;
          right: rem(10);
          z-index: 3;
          opacity: 1;
          span {
            color:#283044;
          }
          @include media(sm) {
            top: rem(10);
            right: rem(24);
          }
          font-size: 2.5rem;
        }

        .guard-safety{
          .guard-safety__title{
            font-size: rem(14);
            max-width: 80%;
            padding-top: 10px;
            @include media(sm) {
              font-size: rem(24);
            }
          }
          .guard-safety__sub{
            font-size: rem(12);
            @include media(sm) {
              font-size: rem(20);
            }
          }
        }

        .container-main__submission{
          padding: rem(10) rem(7) rem(22);
          @include media(sm) {
            padding: rem(22) rem(25) rem(35);
          }
          & .modal-body-content_title {
            padding-top: 10px;
            max-width: 80%;
            line-height: rem(16);
            @include media(sm) {
              padding-top: 0;
              line-height: rem(28);
            }
            span{
              font-size: rem(14);
              @include media(sm) {
                font-size: rem(24);
              }
            }
          }
          & .modal-body-content_subtitle{
              font-size: rem(12);
            @include media(sm) {
              font-size: rem(20);
            }
          }
        }
      }
    }
  }
}