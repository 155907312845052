.page-loader  {
    background-color: #fff;
    z-index: 2050;
}

.section-loader {
    background-color: rgba(#fff, 0.8);
    z-index: 2050;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}