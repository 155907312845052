.job-location-large {
  #job-location-map {
    height: rem(470);
    @include media(sm) {
      height: rem(600);
    }
  }
}

#job-location-map {
  width: 100%;
  height: 350px;
}

.gm-style-iw-a {
  .gm-style-iw-t {
    .gm-style-iw.gm-style-iw-c {
      background-color: rgba(#fff, 0.6) !important;
      font-weight: 600;
    }
  }
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: transparent !important;
  background-color: transparent !important;
}

.map-info-window {
  // background: #F9F9F9;
  max-width: rem(200);

  p, a {
    font-size: rem(14);
    line-height: 20px;
  }
  a {
    text-decoration: underline;
    padding-left: 5px;
    &:hover {
      color: var(--primary);
    }
  }
}

