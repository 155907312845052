.breadcrumbs {
    background-color: var(--aqua-blue--light);
    overflow-x: scroll;
    @include media(sm) {
        overflow-x: inherit;
    }
    ul {
        li {
            font-size: rem(13);
            font-weight: 700;
            color: black;
            white-space: nowrap;
            span {
                color: #757575;
                font-size: rem(15);
                font-weight: 400;
                padding: 0 rem(5);
            }
            a {
                font-weight: 400;
                color: var(--primary);
                white-space: nowrap;
            }

        }
        li:last-child {
            padding-right: 20px;
        }
    }
}
