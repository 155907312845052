.card {
    &.card-statistic {
        border-color: #DADADA;
        border-radius: rem(6);
        padding-top: rem(20);
        padding-bottom: rem(20);
        @include media(sm) {
            padding-top: rem(30);
            padding-bottom: rem(30);
        }
        .card-body {
            h6, strong {
                color: var(--dark);
                font-weight: bold;
            }
            h6 {
                font-size: rem(20);
                line-height: 24px;
                margin-bottom: rem(35);
                @include media(sm) {
                    margin-bottom: rem(55);
                }
            }
            strong {
                font-size: rem(40);
                line-height: 28px;
                font-weight: bold;
            }
        }
    }
}
