div {
    &.alert {
        z-index: 2000;
        font-size: rem(16);
        color: var(--dark);
        border-radius: 6px;
        border: 0;
        box-shadow: 0px 1px 8px var(--dark-lighter--2);
        max-width: rem(480);
        width: auto;
        @include media(sm) {
            width: 100%;
        }
        button {
            opacity: 1;
        }
        &-success {
            background: var(--green-light-1);
            button {
                span {
                    color:var(--dark);
                }
            }
        }
        &-danger {
            color: #fff;
            background: var(--danger);
            button {
                span {
                    color: #fff;
                }
            }
        }
    }
}
