footer {
    border-top: rem(1) solid var(--danger);
    padding: rem(20) 0;
    .footer {
        &__brand {
            img {
                max-width: rem(55);
                @include media(md) {
                    margin-right: rem(13);
                }
            }
            small {
                font-weight: 700;
                font-size: rem(14);
            }
            p {
                font-size: rem(14);
                font-weight: 300;
                color: #283044;
            }
        }
        &__external {
            a {
                color: var(--primary);
                font-size: rem(15);
                font-weight: 700;
            }
        }
    }
}