.assign-guard-guards {
    .table-wrapper {
        height: 100%;
        // max-height: 300px;
        // overflow: auto;
        display: inline-block;
    }
}
// .job-details {
    span.list-counter {
        font-size: rem(20);
        font-weight: bold;
        color: #fff;
        width: rem(40);
        height: rem(40);
        background-color: var(--dark);
        border-radius: 50%;
        position: relative;
    }
// }

.form-group {
    .add-item-group {
        button {
            font-size: rem(14);
            font-weight: bold;
            height: auto;
            margin-bottom: rem(5);
        }
    }
}