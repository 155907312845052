
// Media queries
@mixin media($breakpoint) {
    @if $breakpoint == "sm" {
      @media screen and (min-width: 576px) {
        @content;
      }
    }

    @else if $breakpoint == "md" {
      @media screen and (min-width: 768px) {
        @content;
      }
    }

    @else if $breakpoint == "lg" {
        @media screen and (min-width: 992px) {
        @content;
      }
    }

    @else if $breakpoint == "xl" {
        @media screen and (min-width: 1200px) {
        @content;
      }
    }
}

@mixin dropdown {
  button.dropdown-toggle {
    font-weight: bold;
    font-size: rem(15);
    color: var(--primary)
  }
  .dropdown-menu {
      // box-shadow: 0px 0px 0px 2px var(--white) inset;
      // filter: drop-shadow(0px 1px 8px #283044);
      border-radius: 0;
      border: 2px;
      box-shadow: 0px 2px 8px #8C8C8C;
      background-color: var(--aqua-haze);
      padding: 0;
      a {
          color: var(--dark);
          transition: 0.25s ease-in-out;
          padding: rem(9) rem(20);
          &:hover {
              font-weight: 700;
              background-color: #EEEEE5;;
          }
      }
  }
}
@mixin disabledBtnColor {
  background-color: #DEDEDE !important;
  border-color: #DEDEDE !important;
  box-shadow: none !important;
  outline: none !important;
  &:hover {
      cursor: not-allowed;
  }
}

@mixin disabledBtn {

  @include disabledBtnColor;
  &:focus {
      @include disabledBtnColor;
  }
  &:active {
      @include disabledBtnColor;
  }
  &:hover {
      @include disabledBtnColor;
  }
  svg {
      path {
          &:not(&.allow-fill) {
            stroke: rgba(40, 48, 68, 0.5);
          }
          // fill: rgba(40, 48, 68, 0.5);
          &.allow-fill {
            fill: rgba(40, 48, 68, 0.5);
            stroke: transparent;
          }
      }
  }
}


@mixin truncatedTextTable($width) {
  width: rem($width);
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 0;
  vertical-align: middle;
}