// .job-details {
    .schedule-form {
        position: relative;
        // &--disabled {
        //     position: relative;
        //     &:after {
        //         content: "";
        //         position: absolute;
        //         width: 100%;
        //         height: 100%;
        //         background-color: rgba(#fff, 0.7);
        //         z-index: 20;
        //         top:0;
        //         left:0;
        //     }
        // }
        .react-datepicker-custom-wrapper, .custom-react-select {
            max-width: rem(167);
            width: 100%;
        }
    }
// }

.schedule-form {
    margin-bottom: rem(60);
    position: relative;
    &:after {
        position: absolute;
        content: "";
        height: 1.5px;
        width: 100%;
        left:0;
        bottom: rem(-20);
        background-color: var(--alto);
    }
    &__subtitle {
        svg {
            path {
                stroke: var(--aqua-blue);
            }
        }
    }
    &-startDate {
        @include media(md) {
            max-width: rem(275);
            width: 100%;
        }
        @include media(lg) {
            max-width: rem(290);
            width: 100%;
        }
    }
    &-startTime {
        @include media(md) {
            max-width: rem(275);
            width: 100%;
        }
        @include media(lg) {
            max-width: rem(290);
            width: 100%;
        }
    }
    &-shiftPeriod {
        width: auto;
        // @include media(md) {
            width: rem(275);
        // }
        @include media(lg) {
            max-width: rem(290);
            width: 100%;
        }
    }
    .form-group__custom-checkbox label {
        margin-bottom: 0;
    }
    .holidays {
        &-item {
            &__name {
                &:before {
                    width: rem(25);
                }
            }
        }
    }
}

// Date Dropdown
.react-datepicker-custom-wrapper {
    .react-datepicker {
        border: 1px solid #BCB8B2;
        border-radius: rem(6);
        &__triangle {
            display: none;
        }
        &__header {
            background-color: #fff;
            border: 0;
           
        }
        &__day-name {
            font-family: $font-family-base;
            font-size: rem(11);
            font-weight: 700;
            color: var(--grey-light-4);
            text-transform: uppercase;
        }
        &__month {
            background-color: #fff;
        }
        .react-datepicker__day--disabled {
            font-size: rem(13);
            font-weight: 400;
            color: #8b8b8b;
            background: #FFFFFF;
            border: 1px solid #8C8C8C;
            border-radius: 0;
            &:hover {
                background-color: transparent;
            }
        }
        .react-datepicker__day--selected {
            color: var(--aqua-blue);
            border-color: var(--aqua-blue);
            border-radius: 0;
            background-color: #fff;
        }
        &__day {
            font-size: rem(13);
            font-weight: 400;
            color: var(--dark-lighter);
            border: 1px solid var(--aqua-blue--light);
            background-color: var(--aqua-blue--light);
            &:focus {
                box-shadow: none;
                outline: none;
            }
            &:active {
                box-shadow: none;
                outline: none;
            }
            &:hover {
                border-radius: 0;
                background-color: var(--aqua-blue);
                color: var(--dark-lighter);
            }
        }
    }
}