.status-title {
  font-size: 18px;
}

.check-mark-container {
  border-radius: 50%;
  background-color: #1ad559;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}