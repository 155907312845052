h2 {
    &.section-title {
        font-size: rem(20);
        font-weight: 700;
        line-height: 28px;
        // margin: rem(30) 0;
        @media screen and (min-width: 768px) {
            font-size: rem(24);
        }
        svg {
            margin-right: rem(10);
            flex-shrink: 0;
        }
    }
}
.container  {
    h1,h2,h3,h4,h5,h6 {
        font-weight: 700;
        color: var(--dark);
    }
    .align-section-subtitle {
        padding-left: rem(34);
    }
}
