body {
    .container {
        &-xl {
            max-width: 98vw;
            width: 100%;
        }

        @include media(xl) {
          max-width: 85%;
          min-width: 1140px;
        }
    }
}