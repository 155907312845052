$font-family-base: Arial, sans-serif;
$theme-colors: (
    "primary": #19657E,
    "primary-light": #DFEDF2,
    "dark": #283145,
    "dark-lighter": #283044,
    "dark-lighter--2": #BCB8B2,
    "aqua-blue": #4EC3E0,
    "aqua-blue--light": #DFEDF2,
    "aqua-haze": #F7F9FA,
    "alto": #DADADA,
    "danger": #cc0001,
    "green-light-1": #B9F08C,
    "yellow-light-1": #E9EB87,
    "grey-light-1": #F0F3F5,
    "grey-light-2": #F7F9FA,
    "grey-light-3": #F9F9F9,
    "grey-light-4": #292929,
    "white": #fff,
    "aqua-blue-shadow": #BFD4DB,
    "blue": #004e98
);