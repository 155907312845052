nav.primary-nav {
  border-bottom: 5px solid #0077CF;
  padding-top: rem(17);
  padding-bottom: rem(13);
  .navbar-nav {
    #user-account-dropdown {
      min-width: rem(155.88);
    }
    .nav-item {
      @include dropdown;
      &:not(:first-child) {
        @include media(md) {
          margin-left: rem(20);
        }
        @include media(lg) {
          margin-left: rem(30);
        }
      }
      a.nav-link {
        color: var(--primary);
        font-size: 0.9375em;
        font-weight: 700;
        &:after {
          display: none;
        }
      }
      &--replace {
        @include media(sm) {
          order: 1;
        }
        @include media(md) {
          margin-left: rem(30);
          // order: -1;
        }
      }
    }
    .dropdown-menu {
      font-size: 0.9375em;
      @media screen and (max-width: 767px) {
        filter: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        a {
          font-weight: 400;
          color: var(--primary) !important;
        }
        .dropdown-item {
            min-width: 240px;
        }
      }
    }
    .dropdown-menu[aria-labelledby="menu-Jobs"] {
      min-width: rem(220);
    }
  }
}

.sub-dropdown-item {
  display: none;
  // visibility: hidden;
  position: absolute;
  left: 100%;
  top: 0;
  border: 2px;
  box-shadow: 0px 2px 8px #8c8c8c;
  background-color: var(--aqua-haze);
  padding: 0;
  @media screen and (max-width: 767px) {
    width: 100%;
    position: relative;
    left: 0;
    box-shadow: none;
    background-color: #fff;
  }
  &--bottom {
    left: 25%;
    top: 100%;
    min-width: rem(260) !important;
    width: 100%;
    z-index: 10;
  }
}
.sub-dropdown {
  position: relative;
  &:hover .sub-dropdown-item {
    display: block;
  }
}
.sub-dropdown-show .sub-dropdown-item {
  display: block;

}

.dropdown-item.active {
  text-decoration: none;
  background-color: transparent !important;
}
nav.primary-nav .navbar-nav .nav-item .dropdown-menu a:hover {
  background: transparent !important;
}

.nav-logo {
  max-width: rem(191);
  @include media(md) {
    position: absolute;
    top: 20px;
  }
  @include media(lg) {
    position: inherit
  }
}

.nav-date {
  padding-top: 6px;
}
