.tooltip.table-info__tooltip {
    max-width: rem(250);
    // opacity: 1 !important;
    &.show {
        opacity: 1 !important;
    }
    .arrow {
        &:before {
            border-top-color: #BCB8B2;
            // display: none;
        }
    }
    .tooltip-inner {
        border: 1.5px solid #BCB8B2;
        box-shadow: 0px 1px 4px rgba(40, 49, 69, 0.3);
        background: rgba(#F9F9F9, 0.94);
        color: var(--dark);
        font-weight: 400;
        font-size: rem(14);
        line-height: 20px;
        padding: rem(12) rem(10);
        text-align: left;
        border-radius: rem(8);
    }
}
.content-none {
    .dropdown-toggle::after {
        content: none!important;
    }
}
